import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';

import { UqAppBarV2, UqLayoutV2, UqNavbarV2, UqFooterV2 } from '@uq-components';
import UqLayoutLegalPage from '../components/uqLayoutLegalPage';
import SEO from '../components/seo';

import * as styles from './legal.module.scss';

export default function LegalSecurityPage() {
  return (
    <UqLayoutV2>
      <UqAppBarV2
        className={styles.appbar}
        static
      >
        <UqNavbarV2 />
      </UqAppBarV2>

      <div className={styles.root}>
        <div className="master-container legal-security-page">
          <UqLayoutLegalPage
            pageInfo={{
              pageName: 'Legal',
              headerTitle: 'Legal',
              headerSubtitle: '',
              headerInfo: '',
              active: 'security',
            }}
            pageHeaderColStyles={{}}
            headerRowClass="legal-page"
          >
            <SEO
              title="Security"
              description={
                `unitQ is a secure and compliant quality monitoring solution
            for your full application stack. Trusted by category-leading brands.`
              }
            />
            <Container className="text-center content-wrapper">
              <Row className="mt-5">
                <Col className="layout-col">
                  <h3 className="header-blue">Protect your Data </h3>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className="layout-col align-center d-flex flex-column">
                  <div className="legal-content">
                    unitQ is a secure and compliant quality monitoring solution for
                    your full application stack. We meet the industry&apos;s strictest
                    certifications for security, privacy, and control.
                  </div>
                  <div className="d-flex flex-wrap justify-content-around align-content-center align-items-center">
                    <div className="d-flex align-content-between flex-column">
                      <img
                        alt="SOC 2"
                        width="100"
                        height="100"
                        src={'/images/security-page-logos/soc2-logo.jpg'}
                        className="m-3"
                      />
                      <div>SOC 2</div>
                    </div>
                    <div className="d-flex align-content-between flex-column">
                      <img
                        alt="GDPR"
                        width="100"
                        height="100"
                        src={'/images/security-page-logos/gdpr-logo.jpg'}
                        className="m-3"
                      />
                      <div>GDPR</div>
                    </div>
                    <div className="d-flex align-content-between flex-column">
                      <img
                        alt="ISO 27001"
                        width="100"
                        src={'/images/security-page-logos/security-logo-iso.svg'}
                        className="m-3"
                      />
                      <div>ISO 27001</div>
                    </div>
                    <div className="d-flex align-content-between flex-column">
                      <img
                        alt="ISO 27017"
                        width="100"
                        src={'/images/security-page-logos/security-logo-iso.svg'}
                        className="m-3"
                      />
                      <div>ISO 27017</div>
                    </div>
                    <div className="d-flex align-content-between flex-column">
                      <img
                        alt="ISO 27018"
                        width="100"
                        src={'/images/security-page-logos/security-logo-iso.svg'}
                        className="m-3"
                      />
                      <div>ISO 27018</div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col>
                  <div className="content-header">Certification and Compliance</div>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className="layout-col align-center">
                  <div className="legal-content">
                    <hr />
                    <h3>SOC2</h3>
                    <p className="c3">
                      <span className="c0">
                        unitQ is SOC 2 Type II compliant, ensuring the highest standards in
                        data protection and information security policies and
                        controls. For more information, please{' '}
                        <a
                          className="c2"
                          target="_blank"
                          rel="noopener noreferrer"
                          href="/contactus/"
                        >
                          contact us.
                        </a>
                      </span>
                    </p>
                    <hr />
                    <h3>EU GDPR</h3>
                    <p className="c3">
                      <span className="c0">
                        unitQ is compliant with the EU General Data Protection
                        Regulation (EU GDPR).
                      </span>
                    </p>
                    <hr />
                    <h3>ISO 27001, ISO 27017, ISO 27018</h3>
                    <p className="c3">
                      <span className="c5">
                        The unitQ datacenters are compliant with ISO 27001, ISO 27017
                        and ISO 27018 certifications. These internationally accepted
                        security standards cover the systems, applications, people,
                        technology, policies, procedures and data centers serving
                        customers. Our hosting provider, AWS, has achieved all three
                        of these certificates. AWS{' '}
                      </span>
                      <span className="c6">
                        <a
                          className="c2"
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://cloud.google.com/security/compliance/iso-27001/"
                        >
                          ISO 27001
                        </a>
                      </span>
                      <span className="c5">
                        &nbsp;covers the AWS and AWS shared common infrastructure. The{' '}
                      </span>
                      <span className="c6">
                        <a
                          className="c2"
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://cloud.google.com/security/compliance/iso-27017/"
                        >
                          ISO 27017
                        </a>
                      </span>
                      <span className="c5">
                        &nbsp;certification covers cloud security specifically for
                        cloud service providers.{' '}
                      </span>
                      <span className="c6">
                        <a
                          className="c2"
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://cloud.google.com/security/compliance/iso-27018/"
                        >
                          ISO 27018
                        </a>
                      </span>
                      <span className="c0">
                        &nbsp;governs protection of personally identifiable
                        information in public cloud services.
                      </span>
                    </p>
                    <hr />
                    <h3>The unitQ On-Premise Option</h3>
                    <p className="c3">
                      <span className="c5">
                        We can also provide a completely on-premise version of unitQ
                        to our Enterprise customers to enable more flexibility in
                        achieving your compliance requirements. For more information,
                        please{' '}
                      </span>
                      <span className="c6">
                        <a
                          className="c2"
                          target="_blank"
                          rel="noopener noreferrer"
                          href="/contactus/"
                        >
                          contact us
                        </a>
                      </span>
                      <span className="c0">.</span>
                    </p>
                    <hr />
                    <h3>unitQ Subprocessors</h3>
                    <p className="c3">
                      <span className="c0">
                        To support delivery of our Services, unitQ, Inc. (or one of
                        its Affiliates listed below) may engage and use data
                        processors with access to certain customer data (each, a
                        &ldquo;Subprocessor&rdquo;). This page provides important
                        information about the identity, location, and role of each
                        Subprocessor. Terms used on this page but not defined have the
                        meaning set forth in the Customer Terms of Service or
                        superseding written agreement between Customer and unitQ (the
                        &ldquo;Agreement&rdquo;).
                      </span>
                    </p>
                    <h3>Third Parties</h3>
                    <p className="c3">
                      <span className="c0">
                        unitQ currently uses third party Subprocessors to provide
                        infrastructure services and to help us provide customer
                        support and email notifications. Prior to engaging any third
                        party Subprocessor, unitQ performs diligence to evaluate their
                        privacy, security and confidentiality practices and executes
                        an agreement implementing its applicable obligations.
                      </span>
                    </p>

                    <h3>Infrastructure Subprocessors</h3>
                    <p className="c3">
                      <span className="c0">
                        unitQ may use the following Subprocessors to host customer
                        data or provide other infrastructure that helps with delivery
                        of our Services:
                      </span>
                    </p>
                    <p className="c9">
                      <span className="c10" />
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="mt-2 mb-5">
                <Col>
                  <Container className="legal-content">
                    <Row>
                      <Col className="legal-col-regular">
                        <Container>
                          <Row>
                            <Col className="legal-row-header">Entity Name</Col>
                          </Row>
                          <Row>
                            <Col className="legal-row-regular">
                              Amazon Web Services, Inc.
                            </Col>
                          </Row>
                          <Row>
                            <Col className="legal-row-regular">Google Cloud</Col>
                          </Row>
                          <Row>
                            <Col className="legal-row-regular">Microsoft Azure</Col>
                          </Row>
                        </Container>
                      </Col>
                      <div className="w-100sm" />
                      <Col className="legal-col-regular">
                        <Container>
                          <Row>
                            <Col className="legal-row-header">
                              Subprocessing Activities
                            </Col>
                          </Row>
                          <Row>
                            <Col className="legal-row-regular">
                              Cloud Service Provider
                            </Col>
                          </Row>
                          <Row>
                            <Col className="legal-row-regular">
                              Natural language processing
                            </Col>
                          </Row>
                          <Row>
                            <Col className="legal-row-regular">
                              AI Services
                            </Col>
                          </Row>
                        </Container>
                      </Col>
                      <div className="w-100sm" />
                      <Col className="legal-col-regular">
                        <Container>
                          <Row>
                            <Col className="legal-row-header">Entity Country</Col>
                          </Row>
                          <Row>
                            <Col className="legal-row-regular">United States</Col>
                          </Row>
                          <Row>
                            <Col className="legal-row-regular">United States</Col>
                          </Row>
                          <Row>
                            <Col className="legal-row-regular">United States</Col>
                          </Row>
                        </Container>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Container>
          </UqLayoutLegalPage>
        </div>
      </div>

      <UqFooterV2 />
    </UqLayoutV2>
  );
}
